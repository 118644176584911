<template>
  <div class="col-sm-12 footer2 mt-5" >
    <div class="line"></div>
    <div class="row align-items-baseline mt-3 px-3">
      <div class="col-sm-8 p-0">
        <p>
          <a href="https://pial-consult.com"  target="_blank">
            <span v-if="XcustomerID != 'SAVILLS'" style="font-weight: 600;">© DEINRAUM 2022</span> unterstützt von
            <!--            <span style="font-weight: 600;">PiAL CONSULT GmbH Hamburg</span>  <br> {{ $t("footer.alle_rechte") }} | {{ $t("footer.letzte_aenderung") }} {{  getDatum()}}-->
            <span style="font-weight: 600;">PiAL CONSULT GmbH Hamburg</span>  <br> Alle Rechte vorbehalten
          </a>
        </p>
      </div>
      <div class="col-sm-2 text-lg-right  p-0">
        <a @click="showImpressumModal()" class="footer"><span style="font-weight: 600;text-transform: uppercase;text-decoration: underline">IMPRESSUM</span></a>
      </div>
      <div class="col-sm-2 text-lg-right  p-0">
        <a @click="showDatenschutzModal()" class="footer"><span style="font-weight: 600;text-transform: uppercase;text-decoration: underline">Datenschutz</span></a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  props: {
    msg: String,
  },
};
</script>
<style scoped>
span {
  font-family: 'Poppins';
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0;
  text-align: center;
}
.line{
 border:1px solid black;
}
a.footer {
  color: #0B0404;
  font-family: 'Poppins';
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0;
  text-align: left;
}
a.footer:hover {
  color: #0B0404;
}
a{
  color: #0B0404;
}

.footer2 p {
  color: #0B0404;
  font-family: Poppins;
  font-size: 13px;
  letter-spacing: 0;
  line-height: 24px;
}
</style>
