<template>
  <div class="home container">
    <Navigation></Navigation>
    <div id="section-kontakt py-5 my-5">
      <div class="container py-2" id="kontakt-container">
        <div class="d-inline-flex d-flex col-12 px-0">
          <div class="col-lg-8 col-12 px-0">
            <label class="titleHead my-5">Datum und Uhrzeit wählen</label>
            <div class="col-12 px-0">
            <div class="row ">
              <v-date-picker class="mt-3 col-lg-8 col-12" color="green" v-model="booking_datum"
                             title-position="left"
                             :available-dates="disabledDays"
                             :model-config="startpageCalenderConfig"/>
              <div class="col-lg-4 col-12 pt-5 pt-lg-0 mt-5 mt-lg-0">
                <hooper :vertical="true" style="height: 400px" :infiniteScroll="false" :playSpeed="2000" :itemsToShow="7">
                  <template v-for="option in disabledDatesFunc" >
                    <slide :key="option.id" v-model="selectedTime">
                      <div @click="markit(option)" class="my-3 time text-center col-12" :style="option.id == selectedTime ? 'background-color:green;color:#fff' : ''" style="cursor:pointer" >
                        {{ option.start.substr(11, 5) }}
                      </div>
                    </slide>

                  </template>
                  <hooper-navigation slot="hooper-addons"></hooper-navigation>
                </hooper>
              </div>
            </div>
            </div>
            <div class="row col-12">
              <div class="col-lg-6 col-12">
                <label class="title">Deine Kontaktdaten</label>
                <input class="col px-0 mb-2" placeholder="Name" v-model="booking_user_name"/>
                <input class="col px-0 mb-2" placeholder="E-Mail Addresse" v-model="booking_user_email"/>
                <input class="col px-0" placeholder="Telefonnummer" v-model="booking_user_telnr"/>
              </div>
              <div class="col-lg-6 col-12">
                <label class="title pb-4">Erwünschter Kontakt Per:</label>
                <label for="Telefonat" class="control control-radio pb-2">Telefonat
                  <input type="radio" name="radioagerange" value="Telefonat" id="Telefonat" v-model="booking_kontakt_form"/>
                  <div class="control_indicator"></div>
                </label>
                <label for="Microsoft Teams" class="control control-radio pb-2">Microsoft Teams
                  <input type="radio" name="radioagerange" value="Microsoft Teams" id="Microsoft Teams" v-model="booking_kontakt_form"/>
                  <div class="control_indicator"></div>
                </label>
                <label for="Zoom" class="control control-radio">Zoom
                  <input type="radio" name="radioagerange" value="Zoom" id="Zoom" v-model="booking_kontakt_form"/>
                  <div class="control_indicator"></div>
                </label>

              </div>
              <button class="col-12 mt-5 buchen" @click="feedbackSend()">Jetzt buchen</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import {Hooper, Slide, Navigation as HooperNavigation} from 'hooper';
import 'hooper/dist/hooper.css';
import Footer from "../components/Footer";
import Navigation from "../components/Navigation";
import axios from "axios";

export default {
  name: "Home",
  components: {
    Hooper,
    Slide,
    HooperNavigation,
    Footer,Navigation
  },
  data() {
    return {
      slide: 0,
      selectedTime: 0,
      componentKey: 0,
      sliding: null,
      date: new Date(),
      booking_kontakt_form: "",
      booking_user_telnr: "",
      booking_user_name: "",
      timezone: "",
   /*   disabledDays: [
        {id: 1, start:"2022-03-06 05:50:00", end:"2022-03-06 06:00:00"},
        {id: 2, start: "2022-03-08 05:50:00", end: "2022-03-08 06:00:00"},
        {id: 3, start:"2022-03-06 06:50:00", end:"2022-03-06 07:00:00"},
        {id: 4, start: "2022-03-08 06:50:00", end: "2022-03-08 07:00:00"},
      ],*/
      disabledDays:[],
      booking_datum: "",
      booking_time: "",
      showModal: false,
      booking_user_email: "",
      startpageCalenderConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD', // Uses 'iso' if missing
      },
      times: [
        {id: '1', value: '10:00'},
        {id: '2', value: '10:15'},
        {id: '3', value: '10:30'},
        {id: '4', value: '10:45'},
        {id: '5', value: '11:00'},
        {id: '6', value: '11:15'},
        {id: '7', value: '11:30'},
        {id: '8', value: '11:45'},
        {id: '9', value: '12:00'},
        {id: '10', value: '12:15'},
        {id: '11', value: '12:30'},
        {id: '12', value: '12:45'},
        {id: '13', value: '13:00'},
        {id: '14', value: '13:15'},
        {id: '15', value: '13:30'},
        {id: '16', value: '13:45'},
        {id: '17', value: '14:00'},
        {id: '18', value: '14:15'},
        {id: '19', value: '14:30'},
        {id: '20', value: '14:45'},
        {id: '21', value: '15:00'},
        {id: '22', value: '15:15'},
        {id: '23', value: '15:30'},
        {id: '24', value: '15:45'},
        {id: '25', value: '16:00'},
        {id: '26', value: '16:15'},
        {id: '27', value: '16:30'},
        {id: '28', value: '16:45'},
        {id: '29', value: '17:00'},
        {id: '30', value: '17:15'},
        {id: '31', value: '17:30'},
        {id: '32', value: '17:45'},
        {id: '33', value: '18:00'},
      ],
    }
  },
  mounted: function () {
    this.getFreieTermine();
  },
  computed:{
    disabledDatesFunc() {
      if(this.booking_datum ){
        return this.disabledDays.filter(x => (this.booking_datum == x.start.substr(0, 10))) ;
      }
      else{
        return [];
      }
    },
  },
  methods: {
    markit(option) {
      this.selectedTime = option.id;
      this.booking_time = option.start.substr(11, 5);
    },
    getFreieTermine() {
      axios.get('https://kalenderdemo.deinraum.io/api/frei_termine/', {

      }).then((res) => {
        console.log(res.data);
        console.log("lol");
        this.disabledDays = res.data;
      }).catch((err) => {
        console.log(err)
      })
    },
    feedbackSend: function () {
      console.log("feedbackSend");
      var bodyFormData = new FormData();
      bodyFormData.append("booking_user_name", this.booking_user_name);
      bodyFormData.append("booking_kontakt_form", this.booking_kontakt_form);
      bodyFormData.append("booking_user_telnr", this.booking_user_telnr);
      bodyFormData.append("booking_datum", this.booking_datum);
      bodyFormData.append("booking_time", this.booking_time);
      
      bodyFormData.append("booking_id", this.selectedTime);
      
      bodyFormData.append("booking_user_email", this.booking_user_email);
      var url = "https://kalenderdemo.deinraum.io/api/";
      var path = "bookings/";
      var submitTo = url + path;
      var withCredentials = true;

      axios({
        method: "post",
        data: bodyFormData,
        withCredentials: withCredentials,
        // withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          "accept": "application/json",
        },
        url: submitTo,
      })
          .then((resp) => {
            console.log("xyz" + resp.data);
          })
          .catch((error) => {
            console.log(error);
            this.error = true;
          });
    },
  }
};
</script>
<style>

.kontakt {
  font-family: 'Poppins';
  font-size: 110px;
  font-style: normal;
  font-weight: 700;
  line-height: 192px;
  letter-spacing: 0.03em;
  text-align: center;

}

.text {
  font-family: 'Poppins';
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
  text-align: left;
}

.time {
  height: 34px;
  width: 218px;
  border-radius: 5px;
  background-color: white;
  text-align: center;
  font-family: 'Poppins';
  font-size: 18px;
  font-style: normal;
  font-weight: bold;
  line-height: 36px;
  letter-spacing: 0;
  text-align: center;

}

button {
  font-family: 'Poppins';
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: #B9B9B9;
  background-color: transparent;
  border: 1px solid #E8E8E8;
}

input {
  box-sizing: border-box;
  height: 38px;
  text-align: left;
  border: none;
  background-color: transparent;
  border-bottom: 2px solid #1F1F1F;
}

.form-control::placeholder {
  vertical-align: center;
  margin-bottom: auto;
  margin-top: auto;
  font-style: normal;
  color: white !important;
  font-family: 'Poppins';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  padding: 0;

}

.line {
  border-bottom: 2px solid #1F1F1F;
}

.home {
  background-color: #D0D0D0;
}

.title {
  font-family: 'Poppins';
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0;
  text-align: left;

}

.buchen{
  background-color: #1F1F1F;
  color: white;
  height: 40px;
  border-radius: 3px;
}
.vc-container {
  background-color: #dadada ;
  font-family: 'Poppins';
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px;
  letter-spacing: 0;
  text-align: center;
  border: none;
  color:#201A15 !important;
}
.vc-svg-icon{
  color:#201A15 !important;
}
.vc-header {
  color:#201A15 !important;
  margin-bottom:30px;
  font-weight: bold;
}
.vc-weekday{
  color:#201A15 !important;
}
/*.vc-day-content .vc-focusable{
  color:red !important;
  font-weight: 800;
}
.vc-day-content{
  color:#201A15 !important;
}*/
.checkbox {
  color: #201A15;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 27px;
}

.control {
  color: #201A15;
  font-size: 15px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 27px;
  display: block;
  position: relative;
  text-transform: capitalize;
  padding-left: 30px;
  margin-bottom: 10px;
  padding-top: 0;
  cursor: pointer;
}

.control input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.control_indicator {
  position: absolute;
  top: 4px;
  left: 0;
  height: 19px;
  width: 19px;
  background: #ffffff;
  border: 2px solid #201A15;
  border-radius: 50px;
}

.control input:checked ~ .control_indicator {
  background: #ffff;
}

.control input:disabled ~ .control_indicator {
  background: #201A15;
  opacity: 0.6;
  pointer-events: none;
}

.control_indicator:after {
  box-sizing: unset;
  content: '';
  position: absolute;
  display: none;
}

.control input:checked ~ .control_indicator:after {
  display: block;
}

.control-radio .control_indicator {
  border-radius: 50%;
}

.control-radio .control_indicator:after {
  left: 1px;
  top: 1px;
  height: 13px;
  width: 13px;
  border-radius: 50%;
  background: #201A15;
  transition: background 250ms;
}

.control-radio input:disabled ~ .control_indicator:after {
  background: #201A15;
}

.control-radio .control_indicator::before {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 4.5rem;
  height: 4.5rem;
  margin-left: -1.3rem;
  margin-top: -1.3rem;
  background: #201A15;
  border-radius: 3rem;
  opacity: 0.6;
  z-index: 99999;
  transform: scale(0);
}
.hooper-next {
  right: 35%;
}
.hooper-navigation.is-vertical .hooper-prev {
  top: -50px;
  bottom: auto;
  right: 35%;
  left: auto;
  transform: initial;
}
.titleHead{
  font-family: 'Poppins';
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0;
  text-align: left;
  text-transform: uppercase;
}

</style>
