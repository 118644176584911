<template>
<div>
<img src="../assets/images/logo-full-1.png" >
</div>
</template>

<script>
export default {
  name: "Navigation"
}
</script>

<style scoped>

</style>
